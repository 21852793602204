export { default as ProposalBoldFont } from './fonts/arial-bold.ttf';
export { default as ProposalNormalFont } from './fonts/arial.ttf';
export { default as PartnerFont } from './fonts/citi-sans-book-webfont.ttf';
import { alpha } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

import { createDefaultTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

const palette = {
  action: {
    background: '#EBF7F5',
    disabled: '#868B8D',
    disabledBackground: '#E9E8E8',
  },
  error: { main: '#CC0000' },
  info: { main: '#1BB3BC', dark: '#137E84' },
  primary: {
    main: '#00945F',
    dark: '#497629',
    white: '#FFFFFF',
  },
  secondary: { main: '#E97300' },
  success: { main: '#63BA68' },
  text: {
    primary: '#484848',
    secondary: '#8C8C8C',
    disabled: '#BFBFBF',
  },
  warning: { main: '#E97300' },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
};

const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableElevation: false,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        fontSize: '24px',
      },
      sizeMedium: {
        fontSize: '20px',
      },
      contained: {
        backgroundColor: '#E97300',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: () => ({
        '.MuiButton-text': {
          fontSize: 14,
        },
      }),
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled circle': {
          stroke: palette.primary.main,
        },
        '&.Mui-disabled .MuiStepIcon-text': {
          fill: palette.primary.main,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        color: palette.primary.main,
        '&.MuiToggleButton-root': {
          borderColor: alpha(palette.primary.main, 0.5),
        },
        '&.Mui-selected': {
          backgroundColor: palette.action.background,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #F44336',
      },
    },
  },
};

const breakpoints = createBreakpoints({});
const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: 'CitiSans Book',
    fontSize: 60,
    fontWeight: 300,
    lineHeight: '60px',
  },
  h2: {
    fontFamily: 'CitiSans Book',
    fontSize: 54,
    fontWeight: 400,
    lineHeight: '58px',
    [breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '42px',
    },
  },
  h3: {
    fontFamily: 'CitiSans Book',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '28px',
  },
  h4: {
    fontFamily: 'CitiSans Book',
    fontSize: 30,
    fontWeight: 300,
    lineHeight: '34px',
  },
  h5: {
    fontFamily: 'CitiSans Book',
    fontSize: 24,
    fontWeight: 300,
    lineHeight: '32px',
  },
  h6: {
    fontFamily: 'CitiSans Book',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '31px',
  },
  subtitle1: {
    fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '26px',
  },
  subtitle2: {
    fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  body1: {
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: 700,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
  },
  button: {
    fontFamily: 'CitiSans Book',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  caption: {
    fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  overline: {
    fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
});
