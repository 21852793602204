import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { Font } from '@react-pdf/renderer';

import { FontWeight, PdfComponentStyle, TypographyOptions } from '@sigfig/digital-wealth-core';

import { defaultTheme, PartnerFont, ProposalBoldFont, ProposalNormalFont } from '.';

Font.register({
  family: 'Partner Font',
  fonts: [
    { src: ProposalBoldFont, format: 'truetype', fontStyle: 'bold', fontWeight: 700 },
    { src: ProposalNormalFont, format: 'truetype' },
  ],
});

Font.register({
  family: 'Citi Sans',
  fonts: [{ src: PartnerFont, format: 'truetype' }],
});

// Needs to come from local theme
const textPrimaryColor = defaultTheme.palette?.text?.primary ?? '#484848';
const textSecondaryColor = defaultTheme.palette?.text?.secondary ?? '#8C8C8C';
const borderColor = defaultTheme.palette?.grey?.[200] ?? '#E2E8EE';
const border = `1 solid ${borderColor}`;
const fontWeightStandard = ((defaultTheme.typography as TypographyOptions).body1?.fontWeight ?? 400) as FontWeight;
const fontWeightBold = ((defaultTheme.typography as TypographyOptions).body1?.fontWeightBold ?? 700) as FontWeight;
const primaryMainColor = (defaultTheme.palette?.primary as SimplePaletteColorOptions | undefined)?.main ?? '#00945F';
const primaryDarkColor = (defaultTheme.palette?.primary as SimplePaletteColorOptions | undefined)?.dark ?? '#497629';

export const partnerStyle: PdfComponentStyle = {
  verticalDivider: {
    borderRight: `1px solid ${borderColor}`,
  },
  whyDigitalAdviceProBold: {
    fontFamily: 'Partner Font',
    fontWeight: 700,
  },
  border: {
    border,
    borderColor,
    borderRadius: 4,
  },
  accountActivity: {
    fontFamily: 'Partner Font',
  },
  accountActivityBold: {
    fontWeight: fontWeightBold,
  },
  allocationPercentageColumn: {
    width: '20%',
  },
  assetClassLabelColumn: {
    width: '30%',
  },
  securityNameColumn: {
    width: '15%',
  },
  disclosure: {
    fontFamily: 'Partner Font',
    fontFamilyBold: 'Partner Font bold',
  },
  footer: {
    bottom: 30,
  },
  header: {
    color: textSecondaryColor,
    fontSize: 10,
    logoWidth: 120,
  },
  background: {
    backgroundColor: '#FAFAFA',
  },
  performanceSection: {
    fontFamily: 'Partner Font',
  },
  portfolioCompareTitles: {
    fontFamily: 'Partner Font',
    fontWeight: fontWeightBold,
  },
  primaryDarkColor: {
    color: primaryDarkColor,
  },
  primaryMainColor: {
    color: primaryMainColor,
  },
  returns: {
    fontFamily: 'Partner Font',
  },
  riskPlaybackRiskBandText: {
    fontFamily: 'Partner Font',
    fontWeight: fontWeightBold,
    textTransform: 'capitalize',
  },
  riskToleranceTitle: {
    fontFamily: 'Partner Font',
    fontWeight: fontWeightBold,
  },
  riskType: {
    fontFamily: 'Partner Font',
    fontWeight: fontWeightStandard,
  },
  textPrimary: {
    color: textPrimaryColor,
  },
  textSecondary: {
    color: textSecondaryColor,
  },
  disclosureImportantLink: {
    color: primaryMainColor,
  },
  investmentRecommendationModelPortfolio: {
    color: textPrimaryColor,
  },
  whyDigitalAdviceProBenefits: {
    height: '150px',
  },
};
