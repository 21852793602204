import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionTimeoutMessage, usePostHog } from '@sigfig/digital-wealth-core';

import config from '../../config';
import { useApp } from '../../contexts/App';

interface Props {
  children: ReactNode;
  jwt: string;
}

export const KeepAlive: React.FC<Props> = ({ children, jwt }) => {
  const { contentOptions } = useApp();
  const { user, logout } = useAuth0();
  const posthog = usePostHog();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const timeoutMessageRef = useRef({ resetSession: () => {} });
  const history = createBrowserHistory();
  const sessionTimeout = user?.['https://fc.sigfig.com:sessionTimeout'];

  const location = useLocation();
  const partyId = location.pathname.split('/')[2];
  const sessionTimeoutRedirectUrl = partyId ? `/account-summary/${partyId}` : '/home';

  const callKeepAlive = useCallback(async () => {
    if (jwt) {
      try {
        await axios({
          method: 'post',
          url: process.env.NODE_ENV === 'development' ? '/auth/v1/keep-alive' : '/symphony/auth/v1/keep-alive',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        timeoutMessageRef.current.resetSession();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }
  }, [jwt]);

  const debouncedCallKeepAlive = useMemo(() => debounce(callKeepAlive, 1000), [callKeepAlive]);

  useEffect(() => {
    const events = ['click', 'wheel', 'message'];
    events.forEach(event => window.addEventListener(event, debouncedCallKeepAlive));
    return () => {
      events.forEach(event => window.removeEventListener(event, debouncedCallKeepAlive));
    };
  }, [debouncedCallKeepAlive]);

  const handleLogout = useCallback(() => {
    setTimeout(() => {
      posthog?.reset();
      logout({ returnTo: config.auth0.logoutUrl });
    }, 5000);
  }, [posthog, logout]);

  const handleNavigate = useCallback(() => {
    history.push(sessionTimeoutRedirectUrl);
    window.location.reload();
  }, [history, sessionTimeoutRedirectUrl]);

  const memoizedChildren = useMemo(() => children, [children]);
  if (window.location.href.indexOf('widgets') > -1) {
    return <>{memoizedChildren}</>;
  }

  return (
    <>
      {memoizedChildren}
      <SessionTimeoutMessage
        contentOptions={contentOptions}
        logoutCallback={handleLogout}
        navigateCallback={handleNavigate}
        ref={timeoutMessageRef}
        refreshCallback={callKeepAlive}
        timeOutInSeconds={sessionTimeout}
        timeoutThreshold={100}
      />
    </>
  );
};
