import { AssetAllocationTableColumns, CoreConfig, defaultCoreConfig } from '@sigfig/digital-wealth-core';

export const defaultPartnerConfigs: CoreConfig = {
  ...defaultCoreConfig,
  components: {
    ...defaultCoreConfig.components,
    sfDownloadQPR: {
      ...defaultCoreConfig.components.sfDownloadQPR,
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      displayAllocationBar: true,
    },
  },
};

const isIqa1 = window.location.origin === 'https://advisory-connect-dw-iqa1-cfg.qa.sigfig.com';
const isIqa2 = window.location.origin === 'https://advisory-connect-dw-iqa2-cfg.qa.sigfig.com';
const isSqa1 =
  window.location.origin === 'https://advisory-connect-dw-sqa1-cfg.qa.sigfig.com' ||
  window.location.origin === 'https://advisory-connect.qacitizensbank.com';
const isSqa2 =
  window.location.origin === 'https://advisory-connect.qacitizensbank.com' ||
  window.location.origin === 'https://advisory-connect-dw-sqa2-cfg.qa.sigfig.com';
const isStg = window.location.origin === 'https://advisory-connect.qacitizensbank.com';
const isProd = window.location.origin === 'https://advisory-connect.citizensbank.com';
const getConfigValue = ({
  iqa1,
  iqa2,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  iqa2?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }

  if (isIqa2 && iqa2) {
    return iqa2;
  }

  if (isSqa1 && sqa1) {
    return sqa1;
  }

  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

export default {
  contentstack: {
    environment: getConfigValue({
      iqa1: 'dev',
      iqa2: 'dev',
      sqa1: 'citizens_sqa1',
      sqa2: 'citizens_prod', // This value may change
      stg: 'citizens_prod', // Same as prod
      prod: 'citizens_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      iqa1: 'csaffbef3139b4b24aee2cc24c',
      iqa2: 'csaffbef3139b4b24aee2cc24c',
      sqa1: 'csbeebf22381d29cbf99aad72c',
      // This will change same as Prod for now
      sqa2: 'cs6ed37d7a109fb4c8bada2dd6',
      stg: 'cs6ed37d7a109fb4c8bada2dd6', // Same as prod
      prod: 'cs6ed37d7a109fb4c8bada2dd6',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa1: '16sLVhuxT2MFlFRTMFwOO6kM1nfhYo6E',
      iqa2: 'ZJg7RD1wAqPNatszY89mffDIYbWZsDWp',
      sqa1: 'uOX8b8x18HBd2ptl2yKUkBcBnQbxjoie',
      sqa2: 'SCLj5YO1KuKMGs4Cys2A0cs1MiYSy4vj',
      stg: '7NRmHx7CTVuNMFel1Y7IvYxi0bIDJ3Xw',
      prod: 'DZt8o1avbVmcFekEqXdO1QQtj1uPxlcd',
      _: 'IZJ6ez1leyTdhM7cxdsc9PX5rlc2tQGo',
    }),
    domain: getConfigValue({
      iqa1: 'citizens-iqa.us.auth0.com',
      iqa2: 'citizens-iqa2.us.auth0.com',
      sqa1: 'citizens-sqa1.us.auth0.com',
      sqa2: 'citizens-sqa2.us.auth0.com',
      stg: 'citizens-staging.us.auth0.com',
      prod: 'citizens-prod.us.auth0.com',
      _: 'citizens-iqa.us.auth0.com',
    }),
    logoutUrl: 'http://localhost:3000',
  },
  host: getConfigValue({
    iqa1: 'advisory-connect-dw-iqa1-cfg.qa.sigfig.com',
    iqa2: 'advisory-connect-dw-iqa2-cfg.qa.sigfig.com',
    sqa1: 'advisory-connect-dw-sqa1-cfg.qa.sigfig.com',
    sqa2: 'advisory-connect-dw-sqa2-cfg.qa.sigfig.com',
    stg: 'advisory-connect.qacitizensbank.com',
    prod: 'advisory-connect.citizensbank.com',
    _: 'advisory-connect-dw-iqa1-cfg.qa.sigfig.com',
  }),
  jwt: getConfigValue({
    iqa1: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZW5kb3IiOiJjaXRpemVucyIsImFwaVZlcnNpb24iOiIxLjAiLCJpYXQiOiIyMDIyLTAzLTA5VDE2OjA2OjE5Ljc4NVoiLCJleHAiOiIyMDI0LTA0LTMwVDEzOjE4OjA1LjAwMFoiLCJlbnYiOiJ0ZXN0IiwiYXBwIjoiQ29waWxvdCJ9.KmTXDeHMHiYTfbB8x0eRro-LQ3NJIDVlCjsAuSqvYAg',
    // These values need to be updated eventually when we create the jwts for these environments
    // sqa1: 'citizens-sqa1.us.auth0.com',
    // sqa2: 'citizens-sqa2.us.auth0.com',
    // stg: 'citizens-staging.us.auth0.com',
    // prod: 'citizens-prod.us.auth0.com',
    _: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZW5kb3IiOiJjaXRpemVucyIsImFwaVZlcnNpb24iOiIxLjAiLCJpYXQiOiIyMDIyLTAzLTA5VDE2OjA2OjE5Ljc4NVoiLCJleHAiOiIyMDI0LTA0LTMwVDEzOjE4OjA1LjAwMFoiLCJlbnYiOiJ0ZXN0IiwiYXBwIjoiQ29waWxvdCJ9.KmTXDeHMHiYTfbB8x0eRro-LQ3NJIDVlCjsAuSqvYAg',
  }),
  sentry: {
    dsn: getConfigValue({
      iqa1: 'https://ece2115333d9445ea5234bbeadf4fa41@o14749.ingest.sentry.io/6380773',
      sqa1: 'https://a19d4171621648349cbf99a3fd4b73c8@o14749.ingest.sentry.io/6394444',
      // This will change same as SQA1 for now
      sqa2: 'https://a19d4171621648349cbf99a3fd4b73c8@o14749.ingest.sentry.io/6394444',
      stg: 'https://dea54802006d4fa19647b2bb63f25b5d@o14749.ingest.sentry.io/6526250',
      prod: 'https://81b0bd5a14014924908f2a7c5286d9c7@o14749.ingest.sentry.io/6526251',
      _: 'https://f03d680f68a44524a531560c909911df@o14749.ingest.sentry.io/6380774',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_YwMtXmyJjHJ9AiU0tP7Hs1r9UQwYWKp2rcD5c6W94XF',
      _: 'phc_DfeG81drxq7Akc7hhpneG2qiEebBVhyz2G2ohatVv1u',
    }),
  },
};
