import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpIcon from '@mui/icons-material/Help';
import Menu from '@mui/icons-material/Menu';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Divider from '@mui/material/Divider';
import { History } from 'history';
import React, { useState } from 'react';

import {
  Box,
  Button,
  ContentOptions,
  Drawer,
  Grid,
  RteContent,
  Tooltip,
  Typography,
  useGetClientInfo,
  useGetHeaderContent,
  useIsMediumScreen,
} from '@sigfig/digital-wealth-core';

import { Logo } from './logo';

interface Props {
  contentOptions: ContentOptions;
  dataQa?: string;
  history?: History;
  onBack?: () => void;
  partyIdClient?: string;
  showSaveAndExit?: boolean;
  showSubHeader?: boolean;
  showViewClientList?: boolean;
  userType?: string;
}

export const Header: React.FC<Props> = ({
  contentOptions,
  dataQa = 'header',
  history,
  partyIdClient,
  showSaveAndExit = false,
  showSubHeader = false,
  showViewClientList = false,
  onBack,
  userType,
}) => {
  const isMobile = useIsMediumScreen();
  const [open, setOpen] = useState(false);
  const { data: headerComponentData } = useGetHeaderContent({
    variables: contentOptions,
  });
  const {
    data: clientNameData,
    loading: clientNameLoading,
    error: clientNameError,
  } = useGetClientInfo({
    skip: !partyIdClient,
    variables: { partyId: partyIdClient ?? '' },
  });
  const partyPerson = clientNameData?.client?.party?.partyPerson;
  const clientName = partyPerson?.displayName
    ? partyPerson.displayName
    : partyPerson?.givenName && partyPerson?.familyName
    ? `${partyPerson.givenName} ${partyPerson.familyName}`
    : undefined;

  const content = headerComponentData?.all_header?.items?.[0];

  const getMenuActions = () => (
    <Grid
      alignItems={isMobile ? 'left' : 'center'}
      container
      flexDirection={isMobile ? 'column' : 'row'}
      height="100%"
      item
      justifyContent="right"
      xs={showSaveAndExit ? 3 : 12}
    >
      {content?.help_tooltip_content && (
        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {!isMobile && showViewClientList && <Divider orientation="vertical" sx={{ mr: 4, height: 32 }} />}
          <Tooltip
            tooltipContent={
              <>
                <Typography sx={{ fontWeight: 700 }} variant="inherit">
                  {content?.help_tooltip_header}
                </Typography>
                <RteContent data={content?.help_tooltip_content} />
              </>
            }
          >
            <Button startIcon={<HelpIcon />} sx={{ color: 'text.primary' }}>
              <Typography component="span" mr={4} variant="subtitle2">
                {content?.help_button_text}
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      )}
      {showSaveAndExit && (
        <Grid item sx={{ mr: 2 }}>
          <Button onClick={onBack} sx={{ color: 'primary.main', border: '1px solid' }}>
            <Typography variant="body1">{content?.save_exit_text}</Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const headerStyle = {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  };

  return (
    <>
      <Grid container data-qa={dataQa} sx={{ ...headerStyle, height: '80px' }}>
        <Grid item pl={4} xs={9}>
          <Logo />
        </Grid>
        {isMobile && (
          <Grid data-qa={`${dataQa}-actions`} item textAlign="right" xs={3}>
            <Button
              aria-label="Menu actions"
              data-qa={`${dataQa}-menu-mobile`}
              onClick={() => setOpen(true)}
              sx={{ color: 'text.primary' }}
            >
              <Menu />
            </Button>
            <Drawer
              actions={<Box sx={{ display: 'flex', flexDirection: 'column' }}>{getMenuActions()}</Box>}
              onClose={() => setOpen(false)}
              open={open}
            />
          </Grid>
        )}

        {!isMobile && getMenuActions()}
      </Grid>
      {showSubHeader && !clientNameLoading && !clientNameError && clientName && (
        <Grid
          data-qa={`${dataQa}-subheader`}
          item
          sx={{
            py: 2,
            backgroundColor: 'action.background',
            color: 'primary.main',
          }}
          xs={12}
        >
          <RteContent
            config={{ clientName }}
            data={content?.subheader_text ?? ''}
            sx={{ textAlign: 'center' }}
            variantMapping={{ h6: 'div' }}
          />
        </Grid>
      )}
    </>
  );
};
