/* eslint-disable @typescript-eslint/no-shadow */
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import * as queryString from 'query-string';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';

import {
  ApolloProvider,
  Box,
  cacheTypePolicies as symphonyCacheTypePolicies,
  CircularProgress,
  CoreConfigProvider,
  createTheme,
  DisclosurePage,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  Grid,
  HttpLink,
  InMemoryCache,
  Locale,
  Partner,
  PerformanceLogin,
  Product,
  setContext,
  ThemeProvider,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { KeepAlive } from '../auth/KeepAlive';
import { Header } from '../components/Header';
import config, { defaultPartnerConfigs } from '../config';
import { AppProvider, useApp } from '../contexts/App';
import { defaultTheme } from '../theme';
import { partnerStyle } from '../theme/PdfStyle';

import AccountPerformanceWrapper from './AccountPerformance';

const Disclosure = lazy(() => import('../components/Disclosure'));

const ProtectedRoute = lazy(() => import('../auth').then(({ ProtectedRoute }) => ({ default: ProtectedRoute })));
const ProtectedCompatRoute = lazy(() =>
  import('../auth').then(({ ProtectedCompatRoute }) => ({ default: ProtectedCompatRoute })),
);

function App(): unknown {
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const [jwt, setJwt] = useState('');
  const [sessionJwt, setSessionJwt] = useState('');
  const [faPartyId, setFaPartyId] = useState('');
  const posthog = usePostHog();
  const { contentOptions } = useApp();

  useEffect(() => {
    if (user) {
      setJwt(user['https://fc.sigfig.com:frontEndJwt']); // TODO: Get namespace (fc.sigfig.com) from a config https://jira.sigfig.com/browse/DA2-818
    }
  }, [user]);

  useEffect(() => {
    const advisorPartyId = user?.['https://fc.sigfig.com:partyId'];
    if (advisorPartyId && faPartyId !== advisorPartyId) {
      setFaPartyId(advisorPartyId);
      posthog?.identify(advisorPartyId);
    }
  }, [user, faPartyId, posthog]);

  const handleSessionJwtUpdate = useCallback((newJwt: string) => {
    setSessionJwt(newJwt);
  }, []);

  if (isLoading || (isAuthenticated && jwt === '')) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error.message}</div>; // TODO: Perhaps the error should be thrown since auth would have failed.
  }

  const symphonyAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      (networkError as any)?.result?.status === 403 &&
      (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
    ) {
      logout({ returnTo: config.auth0.logoutUrl });
    }
  });

  // TODO: Fix apollo client parameters
  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: from([
      errorLink,
      symphonyAuthLink.concat(
        new HttpLink({
          uri,
          fetch,
          headers:
            process.env.NODE_ENV === 'development'
              ? {
                  connection: 'keep-alive',
                }
              : undefined,
        }),
      ),
    ]),
  });
  const symphonyUrl = process.env.NODE_ENV === 'development' ? '/ui/graphql' : '/symphony/ui/graphql';

  const client = getApolloClient({
    links: [
      getContentstackHttpLinkConfig(
        config.contentstack.environment as string,
        config.contentstack.deliveryToken as string,
      ),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
      },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  // TODO: This can be removed once all the page level containers have been migrated to the partner repo.
  const props = {
    contentOptions: {
      locale: Locale.en_us,
      partner: Partner.Citizens,
      product: Product.DigitalWealth,
    },
  };

  const DisclosureComponent = () => {
    let page: DisclosurePage;
    const { pathname } = useLocation();

    if (pathname.includes('/details')) {
      page = 'RCE';
    } else {
      return null;
    }

    return (
      <Grid container justifyContent="center" sx={{ backgroundColor: 'background.default', mt: 7, mb: 2 }}>
        <Grid item xs={10}>
          <Disclosure {...props} page={page} />
        </Grid>
      </Grid>
    );
  };

  const PerformanceLoginComponent = React.memo((routeComponentProps: RouteComponentProps<{ partyId: string }>) => {
    const {
      match: {
        params: { partyId },
      },
    } = routeComponentProps;
    const { search } = useLocation();
    const { identifier } = queryString.parse(search);

    // We are passing in the query params to determine which identifier to show via the email link
    const showPassportField = identifier === 'passport';

    const staticJwt = config.jwt;

    // Memoize the props object to prevent unnecessary re-renders
    const performanceLoginProps = useMemo(
      () => ({
        clientId: 'Citizens_ADVISOR_TOOLS',
        contentstackConfig: config.contentstack,
        partnerStyles: partnerStyle,
        staticJwt,
        ...routeComponentProps,
        ...props,
        onJwtUpdate: handleSessionJwtUpdate,
        originalHost: config.host,
        partnerId: Partner.Citizens,
        partyId,
        sessionJwt,
        showPassportField,
      }),
      [staticJwt, routeComponentProps, partyId, showPassportField],
    );

    return (
      <>
        <Header {...props} />
        <Grid container justifyContent="center">
          <Grid item py={4} xs={12}>
            <PerformanceLogin {...performanceLoginProps} />
          </Grid>
        </Grid>
      </>
    );
  });

  const AccountPerformanceComponent = (
    routeComponentProps: RouteComponentProps<{ managedProductId: string; partyId: string }>,
  ) => {
    const {
      history,
      match: {
        params: { partyId, managedProductId },
      },
    } = routeComponentProps;
    return (
      <>
        <Header {...props} onBack={() => history.replace(`/account-summary/${partyId}/details/${managedProductId}`)} />
        <AccountPerformanceWrapper {...routeComponentProps} partyIdFA={faPartyId} />
        <DisclosureComponent />
      </>
    );
  };

  return (
    <AppProvider>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CoreConfigProvider value={defaultPartnerConfigs}>
          <ApolloProvider client={client}>
            <KeepAlive {...props} jwt={sessionJwt}>
              <Suspense
                fallback={
                  <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress disableShrink />
                  </Box>
                }
              >
                <Switch>
                  <ProtectedRoute
                    component={AccountPerformanceComponent}
                    path="/account-summary/:partyId/performance/:managedProductId"
                  />
                  <Route component={PerformanceLoginComponent} path="/login/:partyId" />
                  {/* TODO: Add page not found component */}
                  <ProtectedRoute path="/" />
                </Switch>
              </Suspense>
            </KeepAlive>
          </ApolloProvider>
        </CoreConfigProvider>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
